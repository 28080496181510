import '@css/main.scss';
import '@js/bootstrap';

import {Header} from '@js/utils/Header';
import {Slider} from '@js/utils/Slider';
import {Accordion} from "@js/utils/Accordion";
import {Team} from "./utils/Team";

window.onload = () => {

  new Header()
  new Slider()
  new Accordion()
  new Team()

}
