export class Team {

  constructor () {

    const filters = document.querySelectorAll('.team-filters .filter')
    const members = document.querySelectorAll('.team .c-person')

    filters.forEach(f => {

      f.addEventListener('click', (e) => {

        filters.forEach(filter => {
          filter.classList.remove('active')
        })

        f.classList.add('active')

        const term = f.getAttribute('data-term')

        if(term === 'all') {
          members.forEach(member => {
            member.style.display = 'block'
          })
          return
        }

        members.forEach(member => {

          const member_term = member.getAttribute('data-term')

          if (member_term === term) {
            member.style.display = 'block'
          } else {
            member.style.display = 'none'
          }

        })

      })

    })

  }

}
